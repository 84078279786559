import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { environment } from '../../../environments/environment';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, interval, map, startWith, switchMap, tap } from 'rxjs';
import { SecureStorageService } from './storage.service';

interface TokenResponse {
    access_token: string;
    refresh_token?: string;
    expires_in: number;
    token_type: string;
    scope: string;
}

interface TokenState {
    accessToken: string | undefined;
    tokenType: string | undefined;
    scope: string | undefined;
    expiresAt: number | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class OauthService {
    tokenExpiresInFormatted = computed(() => {
        const seconds = this.tokenExpiresIn();
        if (seconds <= 0) return 'Expired';

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        if (minutes === 0) {
            return `${remainingSeconds}s`;
        }
        return `${minutes}m ${remainingSeconds}s`;
    });
    private destroyRef = inject(DestroyRef);
    private document = inject(DOCUMENT);
    private baseHref = inject(APP_BASE_HREF);
    private router = inject(Router);
    private storage = inject(SecureStorageService);
    private tokenState = signal<TokenState>({
        accessToken: undefined,
        tokenType: undefined,
        scope: undefined,
        expiresAt: undefined,
    });
    isAuthenticated = computed(() => {
        const { accessToken } = this.tokenState();
        return !!accessToken && this.tokenExpiresIn() > 30; // 30-second buffer
    });
    isAuthenticated$ = toObservable(this.isAuthenticated);
    private refreshToken = signal<string | undefined>(undefined);
    // Token initialization state
    private initializing = signal<boolean>(false);
    // Timer observable for token expiration
    private timer$ = interval(1000).pipe(
        startWith(0),
        map(() => Date.now()),
        takeUntilDestroyed(this.destroyRef),
    );
    // Token expiration tracking
    private tokenExpirationTime$ = toObservable(this.tokenState).pipe(
        map((state) => state.expiresAt),
        distinctUntilChanged(),
    );
    private timeRemaining$ = this.timer$.pipe(
        switchMap((now) =>
            this.tokenExpirationTime$.pipe(
                map((expiresAt) => {
                    if (!expiresAt) return 0;
                    return Math.max(0, Math.floor((expiresAt - now) / 1000));
                }),
            ),
        ),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
    );
    // Public signals and observables
    tokenExpiresIn = toSignal(this.timeRemaining$, { initialValue: 0 });
    private readonly storageKeys = {
        accessToken: 'access_token',
        refreshToken: 'refresh_token',
        tokenType: 'token_type',
        scope: 'scope',
        expiresAt: 'expires_at',
        codeVerifier: 'code_verifier',
        state: 'oauth_state',
        returnRoute: 'return_url',
    };

    private authConfig = {
        issuer: environment.finbootApiUrl,
        clientId: environment.clientId,
        scope: 'full_access',
        responseType: 'code',
        tokenEndpoint: `${environment.finbootApiUrl}/v1/auth/token`,
        loginUrl: `${environment.finbootApiUrl}/v1/auth/authorize`,
        logoutUrl: `${environment.finbootApiUrl}/v1/auth/logout`,
    };

    constructor() {
        // Setup automatic token refresh for tokens about to expire
        this.timeRemaining$
            .pipe(
                filter((timeLeft) => timeLeft < 10 && timeLeft > 0),
                filter(() => !!this.refreshToken()),
                tap(() => {
                    this.silentRefresh().catch((error) => {
                        console.error('Silent refresh failed:', error);
                        this.startAuthFlow();
                    });
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();

        // Initialize authentication state
        this.initializeAuth();
    }

    /**
     * Initialize authentication state by loading stored tokens and handling expired tokens
     */
    async initializeAuth(): Promise<void> {
        this.initializing.set(true);
        try {
            await this.loadStoredTokens();

            // Calculate time remaining directly instead of using tokenExpiresIn signal
            // which might not be updated yet through the reactive chain
            const expiresAt = this.tokenState().expiresAt;
            const timeRemaining = expiresAt
                ? Math.max(0, Math.floor((expiresAt - Date.now()) / 1000))
                : 0;

            const hasAccessToken = !!this.tokenState().accessToken;
            const hasRefreshToken = !!this.refreshToken();

            if (!hasAccessToken && hasRefreshToken) {
                // No access token but have refresh token - try to refresh
                try {
                    await this.silentRefresh();
                } catch (error) {
                    console.error('Failed to refresh token during initialization:', error);
                    // Don't automatically start auth flow - wait for user action
                }
            } else if (hasAccessToken && timeRemaining <= 30 && hasRefreshToken) {
                // Access token is expired or about to expire - try to refresh
                try {
                    await this.silentRefresh();
                } catch (error) {
                    console.error('Failed to refresh expired token during initialization:', error);
                    // Don't automatically start auth flow - wait for user action
                }
            }
        } catch (error) {
            console.error('Error during auth initialization:', error);
        } finally {
            this.initializing.set(false);
        }
    }

    getAccessToken(): string | undefined {
        return this.tokenState().accessToken;
    }

    getTokenType(): string | undefined {
        return this.tokenState().tokenType;
    }

    getScope(): string | undefined {
        return this.tokenState().scope;
    }

    getExpiresAt(): number | undefined {
        return this.tokenState().expiresAt;
    }

    getAuthorizationHeader(): string | undefined {
        const { accessToken, tokenType } = this.tokenState();
        return accessToken && tokenType ? `${tokenType} ${accessToken}` : undefined;
    }

    /**
     * Ensures the user is authenticated, refreshing token if needed or starting auth flow
     * @returns Promise that resolves when authentication is confirmed
     */
    async ensureAuthenticated(returnUrl?: string): Promise<boolean> {
        // Wait if initialization is still in progress
        while (this.initializing()) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        // Calculate authentication state directly to avoid relying on reactive signals
        // that might not have propagated yet
        const { accessToken, expiresAt } = this.tokenState();
        const hasValidToken = !!accessToken && !!expiresAt && (expiresAt - Date.now()) / 1000 > 30;

        if (hasValidToken) {
            return true;
        }

        // If we have a refresh token, try to use it
        if (this.refreshToken()) {
            try {
                await this.silentRefresh();
                return true;
            } catch (error) {
                console.error('Failed to refresh token during ensureAuthenticated:', error);
                // Fall through to start auth flow
            }
        }

        // If we get here, we need to start the auth flow
        await this.startAuthFlow(returnUrl);
        return false;
    }

    async startAuthFlow(returnUrl?: string): Promise<void> {
        const urlToStore = returnUrl || '/';
        await this.storage.setItem(this.storageKeys.returnRoute, urlToStore);

        const codeVerifier = await this.generateCodeVerifier();
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        const state = this.generateState();

        await Promise.all([
            this.storage.setItem(this.storageKeys.codeVerifier, codeVerifier),
            this.storage.setItem(this.storageKeys.state, state),
        ]);

        const authUrl = new URL(this.authConfig.loginUrl);
        authUrl.searchParams.set('client_id', this.authConfig.clientId);
        authUrl.searchParams.set(
            'redirect_uri',
            `${this.document.location.origin}${this.baseHref}callback`,
        );
        authUrl.searchParams.set('response_type', this.authConfig.responseType);
        authUrl.searchParams.set('scope', this.authConfig.scope);
        authUrl.searchParams.set('code_challenge', codeChallenge);
        authUrl.searchParams.set('code_challenge_method', 'S256');
        authUrl.searchParams.set('state', state);

        window.location.href = authUrl.toString();
    }

    async silentRefresh(): Promise<void> {
        const currentRefreshToken = this.refreshToken();
        if (!currentRefreshToken) {
            throw new Error('No refresh token available');
        }

        try {
            const body = new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: this.authConfig.clientId,
                refresh_token: currentRefreshToken,
            });

            const response = await fetch(this.authConfig.tokenEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Token refresh failed');
            }

            const tokenResponse = await response.json();
            await this.handleTokenResponse(tokenResponse);
            return;
        } catch (error) {
            console.error('Silent refresh failed:', error);
            // Clear the refresh token if the server rejected it
            this.refreshToken.set(undefined);
            await this.storage.removeItem(this.storageKeys.refreshToken);
            throw error;
        }
    }

    async handleCallback(code: string, state: string): Promise<void> {
        try {
            const [storedState, codeVerifier] = await Promise.all([
                this.storage.getItem(this.storageKeys.state),
                this.storage.getItem(this.storageKeys.codeVerifier),
            ]);

            if (!storedState || storedState !== state) {
                throw new Error('Invalid state parameter');
            }

            if (!codeVerifier) {
                throw new Error('No code verifier found');
            }

            const tokenResponse = await this.exchangeCodeForToken(code, codeVerifier);
            await this.handleTokenResponse(tokenResponse);

            // Clean up auth flow storage
            this.storage.removeItem(this.storageKeys.state);
            this.storage.removeItem(this.storageKeys.codeVerifier);

            // Navigate back to return URL
            const returnUrl = (await this.storage.getItem(this.storageKeys.returnRoute)) ?? '/';
            this.storage.removeItem(this.storageKeys.returnRoute);
            await this.router.navigateByUrl(returnUrl);
        } catch (error) {
            console.error('Token exchange failed:', error);
            const returnUrl = (await this.storage.getItem(this.storageKeys.returnRoute)) ?? '/';
            this.startAuthFlow(returnUrl);
            throw error;
        }
    }

    async logout(): Promise<void> {
        try {
            // Clear all storage first
            await this.storage.clear();

            // Reset state
            this.tokenState.set({
                accessToken: undefined,
                tokenType: undefined,
                scope: undefined,
                expiresAt: undefined,
            });
            this.refreshToken.set(undefined);

            // Build logout URL with necessary parameters
            const logoutUrl = new URL(this.authConfig.logoutUrl);

            // Add post_logout_redirect_uri if needed
            logoutUrl.searchParams.set(
                'post_logout_redirect_uri',
                `${this.document.location.origin}${this.baseHref}`,
            );

            // Optional: Add client_id if your backend expects it
            logoutUrl.searchParams.set('client_id', this.authConfig.clientId);

            // Redirect to logout URL
            window.location.href = logoutUrl.toString();
        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        }
    }

    private async loadStoredTokens(): Promise<void> {
        try {
            const [accessToken, tokenType, scope, expiresAt, storedRefreshToken] =
                await Promise.all([
                    this.storage.getItem(this.storageKeys.accessToken),
                    this.storage.getItem(this.storageKeys.tokenType),
                    this.storage.getItem(this.storageKeys.scope),
                    this.storage.getItem(this.storageKeys.expiresAt),
                    this.storage.getItem(this.storageKeys.refreshToken),
                ]);

            this.tokenState.set({
                accessToken: accessToken ?? undefined,
                tokenType: tokenType ?? undefined,
                scope: scope ?? undefined,
                expiresAt: Number(expiresAt) || undefined,
            });

            if (storedRefreshToken) {
                this.refreshToken.set(storedRefreshToken);
            }
        } catch (error) {
            console.error('Failed to load stored tokens:', error);
        }
    }

    private async handleTokenResponse(tokenResponse: TokenResponse): Promise<void> {
        const expiresAt = Date.now() + tokenResponse.expires_in * 1000;

        // Update signals
        this.tokenState.set({
            accessToken: tokenResponse.access_token,
            tokenType: tokenResponse.token_type,
            scope: tokenResponse.scope,
            expiresAt,
        });

        // Store all values securely
        await Promise.all([
            this.storage.setItem(this.storageKeys.accessToken, tokenResponse.access_token),
            this.storage.setItem(this.storageKeys.tokenType, tokenResponse.token_type),
            this.storage.setItem(this.storageKeys.scope, tokenResponse.scope),
            this.storage.setItem(this.storageKeys.expiresAt, expiresAt.toString()),
        ]);

        if (tokenResponse.refresh_token) {
            await this.storage.setItem(this.storageKeys.refreshToken, tokenResponse.refresh_token);
            this.refreshToken.set(tokenResponse.refresh_token);
        }
    }

    private async exchangeCodeForToken(code: string, codeVerifier: string): Promise<TokenResponse> {
        const body = new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: this.authConfig.clientId,
            code_verifier: codeVerifier,
            code: code,
            redirect_uri: `${this.document.location.origin}${this.baseHref}callback`,
        });

        const response = await fetch(this.authConfig.tokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body.toString(),
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Token exchange failed');
        }

        return await response.json();
    }

    private generateCodeVerifier(): string {
        const random = new Uint8Array(32);
        window.crypto.getRandomValues(random);
        return this.base64UrlEncode(random);
    }

    private async generateCodeChallenge(verifier: string): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const hash = await window.crypto.subtle.digest('SHA-256', data);
        return this.base64UrlEncode(new Uint8Array(hash));
    }

    private base64UrlEncode(buffer: Uint8Array): string {
        const base64 = window.btoa(String.fromCharCode(...buffer));
        return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    private generateState(): string {
        const random = new Uint8Array(16);
        window.crypto.getRandomValues(random);
        return this.base64UrlEncode(random);
    }
}
