import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SecureStorageService {
    private cryptoKey: CryptoKey | undefined = undefined;
    private isSecureContext: boolean;

    constructor() {
        this.isSecureContext = window.isSecureContext;
        if (!this.isSecureContext) {
            console.warn('Running in non-secure context. Some features may be limited.');
        }
        this.initializeKey();
    }

    private async initializeKey(): Promise<void> {
        try {
            // Check if we're in a secure context or localhost
            if (!this.isSecureContext && !window.location.hostname.includes('localhost')) {
                throw new Error('Secure storage requires HTTPS except on localhost');
            }

            const storedKey = localStorage.getItem('app_key');

            if (storedKey) {
                // Import existing key
                const keyArray = new Uint8Array(JSON.parse(storedKey));
                this.cryptoKey = await crypto.subtle.importKey('raw', keyArray, 'AES-GCM', true, [
                    'encrypt',
                    'decrypt',
                ]);
            } else {
                // First time: generate new encryption key
                this.cryptoKey = await crypto.subtle.generateKey(
                    {
                        name: 'AES-GCM',
                        length: 256,
                    },
                    true,
                    ['encrypt', 'decrypt'],
                );

                // Store key for future use
                const exportedKey = await crypto.subtle.exportKey('raw', this.cryptoKey);
                const keyArray = Array.from(new Uint8Array(exportedKey));
                localStorage.setItem('app_key', JSON.stringify(keyArray));
            }
        } catch (error) {
            console.error('Failed to initialize encryption key:', error);
            // Provide a fallback for non-secure contexts
            if (!this.isSecureContext) {
                // Use a simple storage method for development
                this.cryptoKey = undefined;
            } else {
                throw error;
            }
        }
    }

    async setItem(key: string, value: string): Promise<void> {
        if (!this.cryptoKey) {
            await this.initializeKey();
        }

        try {
            // Each encryption gets its own random initialization vector
            const iv = crypto.getRandomValues(new Uint8Array(12));

            // Convert the string to bytes for encryption
            const encodedValue = new TextEncoder().encode(value);

            // Encrypt the data
            const encryptedData = await crypto.subtle.encrypt(
                {
                    name: 'AES-GCM',
                    iv: iv,
                },
                this.cryptoKey!,
                encodedValue,
            );

            // Store both the encrypted data and IV
            const storageData = {
                data: Array.from(new Uint8Array(encryptedData)),
                iv: Array.from(iv),
            };

            localStorage.setItem(key, JSON.stringify(storageData));
        } catch (error) {
            console.error('Failed to encrypt and store data:', error);
            throw error;
        }
    }

    async getItem(key: string): Promise<string | undefined> {
        if (!this.cryptoKey) {
            await this.initializeKey();
        }

        try {
            const storedData = localStorage.getItem(key);
            if (!storedData) return undefined;

            // Get stored data and IV
            const { data, iv } = JSON.parse(storedData);

            // Convert back to Uint8Arrays for decryption
            const encryptedData = new Uint8Array(data);
            const ivArray = new Uint8Array(iv);

            // Decrypt the data
            const decryptedData = await crypto.subtle.decrypt(
                {
                    name: 'AES-GCM',
                    iv: ivArray,
                },
                this.cryptoKey!,
                encryptedData,
            );

            // Convert decrypted data back to string
            return new TextDecoder().decode(decryptedData);
        } catch (error) {
            console.error('Failed to decrypt data:', error);
            return undefined;
        }
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    clear(): void {
        localStorage.clear();
    }
}
